import { Banner } from "~/components/ui/banner";
import { Modal } from "~/components/ui/modal";
import type { PageDocumentBase } from "~/types/sanity-schema";
import { asLink, asText } from "~/utils/sanity-helpers";
import { BlockContent } from "~/components/block-content";
import { ButtonLink } from "~/components/ui/button";
import { useFeatureFlagPayload } from "posthog-js/react";

interface Props {
	pageBaseData: PageDocumentBase;
}

export const PageBanner = ({
	data,
}: {
	data: PageDocumentBase | undefined;
}) => {
	if (!data) return null;

	const { infoBanner } = data;
	const slug = infoBanner?.slug?.current;

	return (
		<Banner
			position="top"
			id={slug || "info-banner"}
			cta={{
				title: infoBanner?.primaryCTA?.title,
				url: asLink(infoBanner?.primaryCTA),
			}}
			tagline={infoBanner?.tagline}
		>
			{infoBanner?.title}
		</Banner>
	);
};

export const PostHogBanner = () => {
	type BannerFeatureFlagPayload = {
		key: string;
		tagline: string;
		title: string;
		link_text?: string;
		link_url?: string;
	};

	const payload = useFeatureFlagPayload(
		"marketing-site-location-based-banner"
	) as BannerFeatureFlagPayload | null;

	if (!payload) return null;

	const { key, tagline, title, link_text, link_url } = payload;

	return (
		<Banner
			position="top"
			id={key}
			cta={{
				title: link_text,
				url: link_url,
			}}
			tagline={tagline}
			trackPosition="location-banner"
		>
			{title}
		</Banner>
	);
};

export function PageAddOns({ pageBaseData }: Props) {
	const { modals } = pageBaseData;

	if (!modals) return null;

	return modals.map((modal, index) => {
		const ctaLink = asLink(modal.cta);

		return (
			<Modal key={index} ariaLabel={asText(modal.name)} id={modal.id}>
				{modal.title ? <Modal.Header title={modal.title} /> : null}
				<Modal.Content>
					{modal.content ? <BlockContent value={modal.content} /> : null}
				</Modal.Content>
				<Modal.Footer showCloseButton={false}>
					{ctaLink && modal.cta?.title ? (
						<ButtonLink size="dense" to={ctaLink}>
							{modal.cta.title}
						</ButtonLink>
					) : null}
				</Modal.Footer>
			</Modal>
		);
	});
}
